import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  Typography,
  Slider,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";

const FiltersDrawer = ({ applyFilters }) => {
  const filterMain = useSelector((state) => state.filter);
  const [filter, setFilter] = useState(filterMain);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const updateFromUseEffectSettings = useRef(false);
  const [settings, setSettings] = useState({
    price: {
      MAX: 300000,
      MIN: 10000,
      marks: [
        {
          value: 10000,
          label: "10.000",
        },
        {
          value: 300000,
          label: "300.000",
        },
      ],
    },
    seats: {
      MIN: 1,
      MAX: 10,
      marks: [
        { value: 1, label: `1 o'rindiq` },
        { value: 10, label: `10 o'rindiq` },
      ],
    },
    sort: [
      { value: "datetime_asc", label: "Kun/vaqt:" }, //Pastdan yuqoriga
      { value: "datetime_desc", label: "Kun/vaqt:" }, //Yuqoridan pastga
      { value: "price_asc", label: "Narxi:" }, //Pastdan yuqoriga
      { value: "price_desc", label: "Narxi:" }, //Yuqoridan pastga
      { value: "rating_asc", label: "Haydovchining reytingi:" }, //Pastdan yuqoriga
      { value: "rating_desc", label: "Haydovchining reytingi:" }, //Yuqoridan pastga
    ],
  });
  const formatFare = (value) => {
    if (!value) return value;
    value = value.toString();
    const numberValue = value.replace(/\D/g, "");
    return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  useEffect(() => {
    // console.log("filterDrawerOpen", filterDrawerOpen);
    if (!filterDrawerOpen) return;
    if (Object.keys(filterMain.ranges).length !== 0) {
      if (updateFromUseEffectSettings.current) {
        updateFromUseEffectSettings.current = false;
      } else {
        updateFromUseEffectSettings.current = true;
        setFilter(filterMain);
        console.log({ filterMain });
        setSettings({
          ...settings,
          price: {
            MAX: filterMain.ranges.price[1],
            MIN: filterMain.ranges.price[0],
            marks: [
              {
                value: filterMain.price[0],
                label: formatFare(filterMain.ranges.price[0]),
              },
              {
                value: filterMain.price[1],
                label: formatFare(filterMain.ranges.price[1]),
              },
            ],
          },
          seats: {
            MIN: filterMain.ranges.seats[0],
            MAX: filterMain.ranges.seats[1],
            marks: [
              {
                value: filterMain.seats[0],
                label: `${filterMain.ranges.seats[0]} o'rindiq`,
              },
              {
                value: filterMain.seats[1],
                label: `${filterMain.ranges.seats[1]} o'rindiq`,
              },
            ],
          },
        });
      }
    }
  }, [filterMain, settings, filterDrawerOpen]);
  const toggleFilterDrawer = (type) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    if (type !== "init") {
      applyFilters(filter);
    }
    setFilterDrawerOpen(!filterDrawerOpen);
  };

  return (
    <>
      <Button
        startIcon={<FilterListIcon />}
        variant="outlined"
        onClick={() => toggleFilterDrawer("init")}
        sx={{ mb: 2 }} // Add some margin below the button
      >
        filtrlash
      </Button>
      <Drawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(!filterDrawerOpen)}
      >
        <Box sx={{ width: 300, padding: "19px" }} role="presentation">
          {/* <Typography variant="h6" gutterBottom>
                Filtrlash
            </Typography> */}
          <FormControl fullWidth sx={{ mb: 3 }} size="small">
            <InputLabel>Saralash</InputLabel>
            <Select
              value={filter.sortBy}
              label="Saralash"
              // sx={{fontSize:10}}
              onChange={(e) => setFilter({ ...filter, sortBy: e.target.value })}
            >
              {settings.sort.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ fontSize: "15px", py: 0 }}
                >
                  {option.label}
                  {option.value.includes("desc") ? (
                    <ArrowUpwardIcon sx={{ mr: 1 }} />
                  ) : (
                    <ArrowDownwardIcon sx={{ mr: 1 }} />
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography>Narx diapazoni</Typography>
          <Box sx={{ position: "relative", pl: 1.3, pr: 1.3, mb: 3 }}>
            <Typography sx={{ position: "absolute", left: 0, bottom: "-8px" }}>
              {settings.price.marks[0].label}
            </Typography>
            <Slider
              // aria-label="Custom marks"e
              // marks={settings.price.marks}
              step={5000}
              value={filter.price}
              valueLabelDisplay="auto"
              name="price"
              min={settings.price.MIN}
              max={settings.price.MAX}
              onChange={(_, price) => setFilter({ ...filter, price })}
            />
            <Typography sx={{ position: "absolute", right: 0, bottom: "-8px" }}>
              {settings.price.marks[1].label}
            </Typography>
          </Box>
          <Typography>Kerakli o'rindiqlar</Typography>
          <Box sx={{ position: "relative", pl: 1.3, pr: 1.3, mb: 3 }}>
            <Typography sx={{ position: "absolute", left: 0, bottom: "-8px" }}>
              {settings.seats.marks[0].label}
            </Typography>
            <Slider
              // marks={settings.seats.marks}
              step={1}
              value={filter.seats}
              valueLabelDisplay="auto"
              name="seats"
              min={settings.seats.MIN}
              max={settings.seats.MAX}
              onChange={(_, seats) => setFilter({ ...filter, seats })}
            />
            <Typography sx={{ position: "absolute", right: 0, bottom: "-8px" }}>
              {settings.seats.marks[1].label}
            </Typography>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={filter.frontSeat}
                onChange={(e) =>
                  setFilter({ ...filter, frontSeat: e.target.checked })
                }
                name="frontSeat"
                color="primary"
              />
            }
            label="Old o'rindiqli mavjud"
          />
          <FormControlLabel
            control={
              <Switch
                checked={filter.presenceOfWoman}
                onChange={(e) =>
                  setFilter({ ...filter, presenceOfWoman: e.target.checked })
                }
                name="presenceOfWoman"
                color="primary"
              />
            }
            label="Ayollar borligi"
          />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => toggleFilterDrawer()}
          >
            Qo'llash
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default FiltersDrawer;
