import * as React from "react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
// import TextField from "@mui/material/TextField";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import roundToNearestHalfHour from "./roundToNearestHalfHour";
export default function DateTimePicker({
  rideId,
  onChange,
  type,
  passenger,
  initialTime,
}) {
  const [value, setValue] = React.useState(dayjs(roundToNearestHalfHour()));
  const [error, setError] = useState("");
  const [rideIdD, setRideIdD] = useState(0);
  useEffect(() => {
    setRideIdD(rideId);
  }, [rideId]);
  useEffect(() => {
    if (initialTime) {
      setValue(dayjs(new Date(initialTime)));
    }
  }, [initialTime]);
  const handleDateChange = (newValue) => {
    if (newValue) {
      if (new Date(newValue.$d).getTime() < new Date().getTime() && !rideIdD) {
        setError("Sana va vaqt kelajakda bo'lishi kerak.");
        onChange(0);
      } else {
        setError("");
        onChange(newValue);
      }
    }
    setValue(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer
        components={['DateTimeField']}
      > */}
      <DateTimeField
        label={
          passenger
            ? "Jo‘nash kuni va vaqtini kiriting"
            : "Jo‘nash kuni va vaqti"
        }
        value={value}
        name="departureDateTime"
        // disabled={!!initialTime}
        minDateTime={new Date()} // Restricts past dates and times
        // renderInput={(params) => (
        //   <TextField {...params} error={!!error} helperText={error} />
        // )}
        error={!!error}
        helperText={
          error ? error : type === "driver" ? "kun/oy vaqti" : "kun/oy"
        }
        // helperText='kun/oy vaqti'
        onChange={handleDateChange}
        format={type === "driver" ? "D/M HH:mm" : "D/M"}
        // format="D/M HH:mm"
        // format="D.M.YYYY HH:mm"
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}
