const searchUrl =
  process.env.NODE_ENV === "production"
    ? !process.env.REACT_APP_WEB_URL.includes("tsnq")
      ? `${process.env.REACT_APP_WEB_URL}/api/search`
      : `http://localhost/api/search`
    : `http://localhost/api/search`;
const orderUrl =
  process.env.NODE_ENV === "production"
    ? !process.env.REACT_APP_WEB_URL.includes("tsnq")
      ? `${process.env.REACT_APP_WEB_URL}/api/order`
      : `http://localhost/api/order`
    : `http://localhost/api/order`;
const userUrl =
  process.env.NODE_ENV === "production"
    ? !process.env.REACT_APP_WEB_URL.includes("tsnq")
      ? `${process.env.REACT_APP_WEB_URL}/api/users`
      : `http://localhost/api/users`
    : `http://localhost/api/users`;

class HttpService {
  searchRides(params) {
    const queryString = `params=${encodeURIComponent(JSON.stringify(params))}`;
    return fetch(searchUrl + `/ride?${queryString}`, { method: "GET" })
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
      });
  }
  async getSettings(telegram_id) {
    try {
      const res = await fetch(
        `${userUrl}/settings?telegram_id=${telegram_id}`,
        {
          method: "GET",
          headers: { "Content-type": "applicaion/json" },
        }
      );
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  async sendToSupport(params) {
    try {
      const url = `https://api.telegram.org/bot${process.env.REACT_APP_SUPPORT_BOT_TOKEN}/sendMessage`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: process.env.REACT_APP_SUPPORT_BOT_USER_ID,
          text: params,
        }),
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  async updateSettings(params) {
    try {
      const res = await fetch(`${userUrl}/settings`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  async getRide(ride_id) {
    try {
      const res = await fetch(`${orderUrl}/active/${ride_id}`, {
        method: "GET",
        headers: { "Content-type": "applicaion/json" },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  async getRating(user_id, page_number) {
    try {
      const res = await fetch(
        userUrl + `/rating?user_id=${user_id}&page_number=${page_number}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      this.sendToSupport({
        message: "frontend getRating " + e.message,
        Stack: e.stack ? e.stack : "",
      });
      throw e;
    }
  }
  async insertSearch(params) {
    try {
      const res = await fetch(`${searchUrl}/insert-search`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  async editRide(params) {
    try {
      const res = await fetch(`${orderUrl}/edit`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  async rateRide(params) {
    try {
      const res = await fetch(userUrl + "/rating", {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(params),
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
  // async getSearchesAreaLevel1() {
  //   const res = await fetch(`${searchUrl}/area_level_1`, {
  //     method: "GET",
  //     headers: { "Content-type": "applicaion/json" },
  //   });
  //   if (!res.ok) {
  //     const errorResponse = await res.json();
  //     const errorMessage = errorResponse.error || "Noma'lum xato";
  //     throw new Error(errorMessage);
  //   }
  //   return res.json();
  // }
  async getSearchesByDirections(from_area, to_area, role, timeFrame) {
    const res = await fetch(
      `${searchUrl}/searches_by_direction?from_area=${from_area}&to_area=${to_area}&role=${role}&timeFrame=${timeFrame}`,
      {
        method: "GET",
        headers: { "Content-type": "applicaion/json" },
      }
    );
    if (!res.ok) {
      const errorResponse = await res.json();
      const errorMessage = errorResponse.error || "Noma'lum xato";
      throw new Error(errorMessage);
    }
    return res.json();
  }
  async getSearchesAreaLevel1(role, timeFrame) {
    const res = await fetch(
      `${searchUrl}/area_level_1?role=${role}&timeFrame=${timeFrame}`,
      {
        method: "GET",
        headers: { "Content-type": "applicaion/json" },
      }
    );
    if (!res.ok) {
      const errorResponse = await res.json();
      const errorMessage = errorResponse.error || "Noma'lum xato";
      throw new Error(errorMessage);
    }
    return res.json();
  }
  // async getSearchesMonitoring(role) {
  //   const res = await fetch(`${searchUrl}/monitoring?role=${role}`, {
  //     method: "GET",
  //     headers: { "Content-type": "applicaion/json" },
  //   });
  //   if (!res.ok) {
  //     const errorResponse = await res.json();
  //     const errorMessage = errorResponse.error || "Noma'lum xato";
  //     throw new Error(errorMessage);
  //   }
  //   return res.json();
  // }
  async getSearchedRides(user_id) {
    try {
      const res = await fetch(searchUrl + `?user_id=${user_id}`, {
        method: "GET",
        headers: { "Content-type": "applicaion/json" },
      });
      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {}
  }
  async bookRide(props) {
    try {
      const res = await fetch(orderUrl + "/book-ride", {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(props),
      });

      if (!res.ok) {
        const errorResponse = await res.json();
        const errorMessage = errorResponse.error || "Noma'lum xato";
        throw new Error(errorMessage);
      }
      return res.json();
    } catch (e) {
      throw e;
    }
  }
}
const instance = new HttpService();
export default instance;
