import * as React from "react";
// import { useRef } from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";

// import CloseIcon from '@mui/icons-material/Close';
import "./Swipe.css";
import FooterButtons from "../FooterButtons";
const drawerBleeding = 0;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "var(--tg-theme-bg-color)",
}));
// const Puller = styled("div")(({ theme }) => ({
//   width: 30,
//   height: 6,
//   backgroundColor: "var(--tg-theme-bg-color)",
//   borderRadius: 0,
//   position: "absolute",
//   top: 8,
//   left: "calc(50% - 15px)",
// }));
function Swipe({
  selector,
  toggleAction,
  closeButtonPos,
  page: PageComponent,
  params,
  enableScrollToTop,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const open = useSelector((state) => state.toggleSwipe[selector]);
  // const scrollToTopElem = useRef(null);
  const toggle = () => {
    if (toggleAction) dispatch(toggleAction());
  };

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(100% - ${drawerBleeding}px)`,
            backgroundColor: theme.palette.background.default,
            backgroundImage: "none",
            overflowY: "scroll",
          },
        }}
      />

      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => toggle()}
        // onOpen={() => toggle()}
        // swipeAreaWidth={drawerBleeding}
        // disableSwipeToOpen={true}
        // ModalProps={{
        //   keepMounted: true,
        // }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          {/* <Puller /> */}
          {/* <div ref={scrollToTopElem}></div> */}
          <PageComponent params={params} />
          {toggleAction && (
            <FooterButtons
              enableScrollToTop={enableScrollToTop}
              closeButtonPos={closeButtonPos}
              // scrollToTopElem={scrollToTopElem}
              // scrollToElement={scrollToElement}
              toggle={toggle}
            />
          )}
        </StyledBox>
      </Drawer>
    </Root>
  );
}

export default Swipe;
