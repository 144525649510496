import React from "react";
import {
  Paper,
  Typography,
  // Button,
  Grid,
  // Avatar,
  Chip,
  // Tooltip,
  // Box,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // For addresses
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // For departure time
import EventSeatIcon from "@mui/icons-material/EventSeat"; // For available space
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // For fare
// import DriveEtaIcon from '@mui/icons-material/DriveEta'; // Visual flair icon
// import StarRateIcon from '@mui/icons-material/StarRate'; // For driver rating
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // To indicate direction
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // For car type
// import FeedbackIcon from '@mui/icons-material/Feedback';
// import StarIcon from '@mui/icons-material/Star';
// import StarHalfIcon from '@mui/icons-material/StarHalf';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { renderRatingStars } from "../../components/RatingsSection/renderRatingStarts";
// const RatingStars = ({ rating }) => {
//   const filledStars = Math.floor(rating / 2);
//   const halfStar = rating % 2 >= 1;
//   const emptyStars = 5 - filledStars - (halfStar ? 1 : 0);
//   return (
//     <Box display="flex">
//       {[...Array(filledStars)].map((_, index) => (
//         <StarIcon key={index} sx={{ color: '#FFD700' }} />
//       ))}
//       {halfStar && <StarHalfIcon sx={{ color: '#FFD700' }} />}
//       {[...Array(emptyStars)].map((_, index) => (
//         <StarBorderIcon key={index} sx={{ color: '#FFD700' }} />
//       ))}
//     </Box>
//   );
// };
const RideCard = ({ ride, handleRideSelector }) => {
  const {
    departure_place_address,
    arrival_place_address,
    departure_datetime,
    ride_start_mode,
    available_space,
    fare,
    average_rating,
    rating_count,
    first_name,
  } = ride;
  const dateTimeFormat = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.${date.getFullYear()}, ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  };
  // const item33Style = {
  //   boxShadow:
  //     "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
  // };
  return (
    <Paper
      onClick={() => {
        handleRideSelector(ride);
      }}
      elevation={3}
      sx={{
        backgroundColor: "var(--tg-theme-bg-color)",
        backgroundImage: "none",
        p: 2,
        margin: "10px 0 10px 16px",
        width: "calc(100% - 18px)",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* <Grid item>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <DriveEtaIcon />
          </Avatar>
        </Grid> */}
        <Grid item xs={12} sm container spacing={2}>
          <Grid item xs={12}>
            <Chip
              sx={{
                height: "auto",
                "& .MuiChip-label": { whiteSpace: "normal" },
              }}
              icon={<LocationOnIcon />}
              label={`${departure_place_address} ➔ ${arrival_place_address}`}
              variant="outlined"
              color="primary"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <AccessTimeIcon sx={{ mr: 1 }} color="secondary" />
            <Typography variant="body2">
              {ride_start_mode === "firstPassenger"
                ? "Yo'lovchi vaqtiga qarab yuriladi"
                : dateTimeFormat(departure_datetime)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <PaymentsOutlinedIcon sx={{ mr: 1 }} color="secondary" />
            <Typography variant="body2">{fare} so'm</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <AccountCircleIcon sx={{ mr: 1 }} color="secondary" />
            <Grid>
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {first_name}
              </Typography>
              {renderRatingStars(parseFloat(average_rating), rating_count, 0.8)}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <EventSeatIcon sx={{ mr: 1 }} color="secondary" />
            <Typography
              variant="body2"
              // sx={{
              //   color: parseInt(available_space) === 0 ? "red" : "initial",
              // }}
            >
              {parseInt(available_space) !== 0
                ? `${available_space} O'rindiqlar mavjud`
                : "Barcha o'rindiqlar band qilingan"}
            </Typography>
          </Grid>
          {/* label={
              parseInt(ride.available_space) !== 0
                ? `${ride.available_space} ta bosh o'rindiq mavjud`
                : "Barcha o'rindiqlar band qilingan"
            }
            sx={{
              ...item35Style,
              color: parseInt(ride.available_space) === 0 ? "red" : "initial",
            }} */}
          {/* <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <DirectionsCarIcon sx={{ mr: 1 }} color="secondary" />
            <Typography variant="body2"> Cobalt</Typography>
          </Grid> */}
          {/* <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <RatingStars rating={7}  /> */}
          {/* <RatingStars rating={driver_rating}  /> */}
          {/* <Typography variant="body2" sx={{ ml: 2 }}>{driver_rating}</Typography> */}
          {/* </Grid> */}
          {/* <Grid item xs={6} sm={6} md={3} display="flex" alignItems="center">
            <FeedbackIcon sx={{ mr: 1 }} color="secondary" />
            <Typography variant="body2" sx={{ flexGrow: 1 }}>{comment}</Typography>
          </Grid> */}
        </Grid>
        {/* <Grid item>
          <Button variant="contained" color="primary" onClick={onSubscribe} endIcon={<ArrowForwardIcon />}>
            Safarga Qo'shilish
          </Button> 
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default RideCard;
