const initialState = {
  seats: [0, 0],
  price: [0, 0],
  frontSeat: false,
  presenceOfWoman: false,
  sortBy: "datetime_asc",
  pageSize: 10,
  pageNumber: 1,
  ranges: {},
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER_VALUE":
      return { ...state, [action.key]: action.value };
    case "SET_FILTER":
      return { ...state, ...action.params };
    case "INIT_FILTER":
      return initialState;
    default:
      return { ...state };
  }
};

export default filterReducer;
