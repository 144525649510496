import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Avatar,
} from "@mui/material";

import httpService from "../../services/http.service";
import { renderRatingStars } from "./renderRatingStarts";
// import { useLocation, useNavigate } from "react-router-dom";
import FooterButtons from "../FooterButtons";
const RatingsSection = ({ user_id, userAvatar, close }) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const { userAvatar, user_id } = location.state || {};
  const [driverComments, setDriverComments] = useState([]);

  const handleExpandClick = useCallback(async () => {
    const comments = await httpService.getRating(user_id, 0);
    setDriverComments(comments);
  }, [user_id]);
  useEffect(() => {
    handleExpandClick();
  }, [handleExpandClick]);
  return (
    user_id && (
      <>
        <List>
          {driverComments.map((comment, index) => (
            <ListItem key={index} alignItems="flex-start">
              <Card variant="outlined" sx={{ width: "100%", mb: 2 }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Avatar
                      alt="Profile Picture"
                      src={userAvatar}
                      sx={{ width: 24, height: 24, marginRight: 1 }}
                    />
                    <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
                      {comment.passenger_name}
                    </Typography>
                    {renderRatingStars(comment.rating, 0, 1.2)}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {comment.comments}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1, fontStyle: "italic" }}
                  >
                    {`E'lon qilingan: ${new Date(
                      comment.created_at
                    ).toLocaleDateString()}`}
                  </Typography>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
        {close && (
          <FooterButtons
            toggle={close}
            closeButtonPos={"bottom"}
            enableScrollToTop={false}
          />
        )}
      </>
    )
  );
};

export default RatingsSection;
