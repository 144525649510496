// import { Loader } from "@googlemaps/js-api-loader";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import "./GoogleMap.css";
import Button from "@mui/material/Button";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
// import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import {
  toggleArrivalMap,
  toggleDepartureMap,
} from "../../redux/actions/mapActions";
import SearchedRidePlaces from "../SearchedRidePlaces/SearchedRidePlaces";
import calculateDistance from "../calculateDistance";
// import useScript from "../useScript";
import { loadMapScript } from "../loadScript";

const autocompleteService = { current: null };
const geocoder = { current: null };

function GoogleMap({
  open,
  toggleDrawer,
  label,
  placeSelected,
  toggleArrivalMap,
  toggleDepartureMap,
  swipeType,
}) {
  const inputRef = React.useRef(null);
  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState(null);

  const [value, setValue] = React.useState(null);
  const [innerPlaceSelected, setInnerPlaceSelected] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const toggleSwipe = () => {
    swipeType === "departure" ? toggleDepartureMap() : toggleArrivalMap();
  };

  const preventSwipe = (e) => {
    e.stopPropagation();
  };

  React.useEffect(() => {
    const mapElement = mapRef.current;
    if (mapElement) {
      // Add event listeners to prevent swipe
      mapElement.addEventListener("touchstart", preventSwipe);
      mapElement.addEventListener("touchmove", preventSwipe);

      // Remove event listeners when the component unmounts
      return () => {
        mapElement.removeEventListener("touchstart", preventSwipe);
        mapElement.removeEventListener("touchmove", preventSwipe);
      };
    }
  }, []);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        const autocompleteOptions = {
          input: request.input,
          componentRestrictions: { country: "UZ" },
        };
        autocompleteService.current.getPlacePredictions(
          autocompleteOptions,
          callback
        );
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google && !geocoder.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService(mapRef);
      geocoder.current = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  // administrative_area_level_1
  async function handlePlaceSelect(value) {
    const {
      structured_formatting: { main_text },
    } = value;
    const mainGeocode = await geocoder.current.geocode({
      placeId: value.place_id,
    });
    const {
      results: [address_componentsAll],
    } = mainGeocode;
    const {
      address_components,
      geometry: {
        location: { lat, lng },
      },
      formatted_address,
    } = address_componentsAll;
    const area_level_1 = address_components.find((al) =>
      al.types.includes("administrative_area_level_1")
    );
    const area_level_2 = address_components.find((al) =>
      al.types.includes("administrative_area_level_2")
    );
    const center = { lat: lat(), lng: lng() };
    setInnerPlaceSelected({
      formatted_address: main_text,
      location: center,
      area_level_1:
        area_level_1 && area_level_1.long_name ? area_level_1.long_name : null,
      area_level_2:
        area_level_2 && area_level_2.long_name ? area_level_2.long_name : null,
    });
    if (inputRef.current) {
      inputRef.current.blur();
    }
    mapRef.current.style.display = "block";
    var initMap = new window.google.maps.Map(mapRef.current, {
      center: center,
      zoom: 15,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
    });
    setMap(initMap);
    var marker = new window.google.maps.Marker({
      position: center,
      map: initMap,
      // title: 'Your Location Title', // Customizable marker title
    });
    initMap.addListener("center_changed", () => {
      var newCenter = initMap.getCenter();
      marker.setPosition(newCenter);
      setInnerPlaceSelected((prev) => {
        return {
          ...prev,
          formatted_address,
          location: { lat: newCenter.lat(), lng: newCenter.lng() },
          locationChange: true,
        };
      });
    });
  }

  async function onApprove() {
    console.log(innerPlaceSelected);
    if (innerPlaceSelected.locationChange) {
      if (!map || !window.google) {
        console.error("Google Maps API is not initialized.");
        return;
      }

      const placesService = new window.google.maps.places.PlacesService(map);
      console.log("placesService", placesService);

      placesService.nearbySearch(
        {
          location: innerPlaceSelected.location,
          radius: "100",
        },
        (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results.length > 0
          ) {
            console.log("placesService 3", results);
            const locations = results.map((item) => {
              const location = item.geometry.location;
              console.log("placesService 4", location);
              return {
                name: item.name,
                location: { lat: location.lat(), lng: location.lng() },
              };
            });
            const nearestLocation = findNearestLocation(
              innerPlaceSelected.location,
              locations
            );
            // const location = nearestLocation.location;
            console.log("nearestLocation", nearestLocation);
            const formatted_address = nearestLocation.name;
            const resPlace = { ...innerPlaceSelected, formatted_address };
            setInnerPlaceSelected((prev) => {
              return { ...prev, ...resPlace };
            });
            placeSelected(resPlace);
          } else {
            // console.log("No nearby places found");
            placeSelected(innerPlaceSelected);
          }
        }
      );
    } else {
      placeSelected(innerPlaceSelected);
    }
  }

  function findNearestLocation(currentLocation, locations) {
    let nearestLocation = null;
    let shortestDistance = Number.MAX_VALUE;

    locations.forEach((location) => {
      const distance = calculateDistance(
        currentLocation.lat,
        currentLocation.lng,
        location.location.lat,
        location.location.lng
      );

      if (distance < shortestDistance) {
        nearestLocation = location;
        shortestDistance = distance;
      }
    });

    return nearestLocation;
  }
  const openQuickSearchedRides = async (place) => {
    console.log("place", place);
    placeSelected(place);
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Autocomplete
        id="google-map-demo"
        sx={{ width: 300 }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="Joy tanlanmagan"
        onFocus={() => {
          loadMapScript();
        }}
        onChange={(event, newValue) => {
          // console.log("newValue", newValue);
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handlePlaceSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <div className="autocompleteTextfield">
            <TextField
              inputRef={inputRef}
              {...params}
              label={label}
              fullWidth
            />
          </div>
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      {!map ? (
        <div style={{ padding: "0px 10px" }}>
          <SearchedRidePlaces
            openQuickSearchedRides={openQuickSearchedRides}
            type={swipeType}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        ref={mapRef}
        style={{ width: "100%", flex: 1, display: "none", border: "none" }}
      />
      <div
        className="back-next-buttons"
        style={{
          justifyContent: map ? "space-between" : "flex-start",
          right: map ? "10px" : "auto",
        }}
      >
        <div>
          <Button
            onClick={() => toggleSwipe()}
            variant="contained"
            color="secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              borderRadius: "50%", // Makes the button circular
              minWidth: "auto", // Removes the minimum width
              padding: "10px", // Adjust padding to your preference
              lineHeight: 1, // Adjust line height for proper icon alignment
            }}
          >
            <ArrowBackIcon />
          </Button>
        </div>
        <div>
          <Button
            onClick={onApprove}
            variant="contained"
            color="secondary"
            sx={{
              display: map ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%", // Makes the button circular
              minWidth: "auto", // Removes the minimum width
              padding: "10px", // Adjust padding to your preference
              lineHeight: 1, // Adjust line height for proper icon alignment
            }}
          >
            <CheckIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { toggleArrivalMap, toggleDepartureMap })(
  GoogleMap
);
