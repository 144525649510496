import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import RideCard from "../RideCard/RideCard";
import httpService from "../../services/http.service";
import { useDispatch, useSelector } from "react-redux";
import "./RideList.css";
import { toggleRideDetailsSwipe } from "../../redux/actions/toggleSwipe";
import FiltersDrawer from "../../components/FiltersDrawer";
import RideDetails from "../RideDetails/RideDetails";
import Swipe from "../../components/Swipes/Swipe";
import { initFilter, setFilter } from "../../redux/actions/filter";
import {
  initSearchDetails,
  setSearchDetails,
} from "../../redux/actions/searchDetails";
// import { CircularProgress } from "@mui/material";
import _ from "lodash";
import RideCardSkeleton from "../../components/RideCardSkeleton";
import FooterButtons from "../../components/FooterButtons";
// import shadows from "@mui/material/styles/shadows";
const RideList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchDetails } = useSelector((state) => state.searchDetails);
  // const { isSearchedRidesOpen } = useSelector((state) => state.toggleSwipe);
  const filter = useSelector((state) => state.filter);
  const [rides, setRides] = useState([]);
  // const [userId, setUserId] = useState(0);
  // const [telegramId, setTelegramId] = useState(0);
  const [loading, setLoading] = useState(false);
  const lastRideRef = useRef(null);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [loadNext, setLoadNext] = useState(0);
  const loadedRidePages = useRef(new Set());

  const updateFromUseEffectFilter = useRef(false);
  const [selectedRide, setSelectedRide] = useState(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    console.log("handleObserver", target.isIntersecting);
    if (target.isIntersecting) {
      setLoadNext((pr) => pr + 1);
    }
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 0.5, // Adjust threshold to 1.0 to ensure the item is fully visible
    });
    if (lastRideRef.current) {
      // console.log("IntersectionObserver", lastRideRef.current);
      observer.observe(lastRideRef.current);
    }

    // Cleanup observer to avoid memory leaks
    return () => observer.disconnect();
  }, [handleObserver, rides.length]);

  const applyFilters = useCallback(
    (newFilter) => {
      setRides([]);
      setFullyLoaded(false);
      loadedRidePages.current.clear();
      dispatch(setFilter({ ...filter, ...newFilter, pageNumber: 1 }));
    },
    [dispatch, filter]
  );
  const handleRideSelector = async (ride) => {
    setSelectedRide(ride);
    dispatch({ type: "TOGGLE_RIDE_DETAILS_SWIPE" });
  };
  // eslint-disable-next-line
  const fetchRides = useCallback(
    _.debounce(async () => {
      // console.log("loadNext", loadNext);
      if (updateFromUseEffectFilter.current) {
        updateFromUseEffectFilter.current = false;
      } else {
        updateFromUseEffectFilter.current = true;
        if (
          loadedRidePages.current.has(filter.pageNumber) ||
          loading ||
          fullyLoaded
        ) {
          return;
        }
        setLoading(true);
        try {
          const {
            departurePlace,
            departureDateTime,
            arrivalPlace,
            telegram_id,
            user_id,
            search_id,
            temp_search_id: temp_search_id_wr,
          } = searchDetails;
          const queryParams = {
            user_id,
            telegram_id,
            departure_place_address: departurePlace.formatted_address,
            arrival_place_address: arrivalPlace.formatted_address,
            departure_location_lng: departurePlace.location.lng,
            departure_location_lat: departurePlace.location.lat,
            arrival_location_lng: arrivalPlace.location.lng,
            arrival_location_lat: arrivalPlace.location.lat,
            departure_datetime: departureDateTime,
            departure_area_level_1: departurePlace.area_level_1,
            departure_area_level_2: departurePlace.area_level_2,
            arrival_area_level_1: arrivalPlace.area_level_1,
            arrival_area_level_2: arrivalPlace.area_level_2,
            search_id,
            temp_search_id: temp_search_id_wr,
          };

          // console.log(queryParams);
          const {
            rides,
            updatedFilter: {
              seats,
              price,
              ranges,
              pageNumber,
              temp_search_id_init,
            },
            temp_search_id,
          } = await httpService.searchRides({ queryParams, filter });
          console.log("rides", rides);
          loadedRidePages.current.add(pageNumber);
          dispatch(setSearchDetails({ ...searchDetails, temp_search_id }));
          if (!temp_search_id_init) {
            setRides((prevRides) => [...prevRides, ...rides]);
            setLoading(false);
          } else {
            loadedRidePages.current.clear();
            setLoading(true);
            setRides([]);
            setTimeout(() => {
              setRides(rides);
              setLoading(false);
            });
          }
          dispatch(
            setFilter({
              ...filter,
              seats,
              price,
              ranges,
              pageNumber: !temp_search_id_init ? pageNumber + 1 : 1,
            })
          );

          if (rides.length !== filter.pageSize) {
            setFullyLoaded(true);
          }
        } catch (e) {
          await httpService.sendToSupport({
            message: "frontend searchRides " + e.message,
            Stack: e.stack ? e.stack : "",
          });
          console.log(e);
        }
      }
    }, 1),
    [filter, loading, fullyLoaded, searchDetails, loadNext, dispatch]
  );

  useEffect(() => {
    if (!searchDetails.departurePlace?.formatted_address) {
      const { user_id, telegram_id } = searchDetails;
      const queryParams = new URLSearchParams({
        user_id,
        telegram_id,
      });
      navigate(`/passenger-booking?${queryParams}`);
    } else {
      fetchRides();
    }
  }, [fetchRides, navigate, searchDetails]);
  const goBack = () => {
    loadedRidePages.current.clear();
    setLoading(true);
    setRides([]);
    setLoading(false);
    dispatch(initFilter());
    dispatch(initSearchDetails());
    const { user_id, telegram_id } = searchDetails;

    const queryParams = new URLSearchParams({
      user_id,
      telegram_id,
    });
    navigate(`/passenger-booking?${queryParams}`);
  };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          padding: "15px 6px 0px 10px",
          position: "fixed",
          backgroundColor: "var(--tg-theme-bg-color)",
          top: 0,
          left: 0,
          right: 0,
          boxShadow:
            "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        }}
      >
        <FiltersDrawer applyFilters={applyFilters} />
      </div>
      <div style={{ padding: "0px 6px 20px 10px", marginTop: "80px" }}>
        {!rides || rides.length === 0 ? (
          !loading ? (
            <>Safar Topilmadi</>
          ) : (
            <></>
          )
        ) : (
          <>
            <Grid container spacing={2}>
              {rides?.map((ride, index) => (
                <div
                  key={ride.ride_id}
                  ref={
                    index === rides.length - 3 && !loading && !fullyLoaded
                      ? lastRideRef
                      : null
                  }
                >
                  <RideCard
                    handleRideSelector={handleRideSelector}
                    ride={ride}
                  />
                </div>
              ))}
            </Grid>
          </>
        )}
        {loading ? <RideCardSkeleton /> : <></>}
        <FooterButtons
          toggle={() => goBack()}
          closeButtonPos={"bottom"}
          enableScrollToTop={true}
        />
        <Swipe
          selector={"isRideDetailsOpen"}
          toggleAction={toggleRideDetailsSwipe}
          page={RideDetails}
          params={{ ride: selectedRide }}
          // closeButtonPos={'bottom'}
        />
        {/* {selectedRide?<SwipeRideDetails searchDetails={searchDetails} ride={selectedRide}/>:<></>} */}
        {/* <div className='back-next-buttons' style={{justifyContent: 'center'}}>
          <div>    
            <Button
              onClick={()=>toggleRidesSwipe()}
              variant="contained"
              color="secondary"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%', // Makes the button circular
                minWidth: 'auto',  // Removes the minimum width
                padding: '10px',   // Adjust padding to your preference
                lineHeight: 1      // Adjust line height for proper icon alignment
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RideList;
