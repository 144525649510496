const initialState = {
    isApproveBookingOpen: false,
    isSearchedRidesOpen: false,
    isRideDetailsOpen: false
}
const toggleSwipeReducer = (state = initialState, action)=>{
    switch(action.type){
        case 'TOGGLE_APPROVE_BOOKING_SWIPE': 
            return {
                ...state,
                isApproveBookingOpen: !state.isApproveBookingOpen
            }
        case 'TOGGLE_SEARCHED_RIDES_SWIPE': 
            return {
                ...state,
                isSearchedRidesOpen: !state.isSearchedRidesOpen
            }
        case 'TOGGLE_RIDE_DETAILS_SWIPE': 
            return {
                ...state,
                isRideDetailsOpen: !state.isRideDetailsOpen
            }
        default: 
            return state;
    }
}

export default toggleSwipeReducer;