import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { Radio, RadioGroup } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import httpService from "../../services/http.service";
import StackAlert from "../../components/StackAlert";
import { allVehicles } from "./allVehicles";
import Link from "@mui/material/Link";
const tg = window.Telegram?.WebApp;
const Settings = () => {
  const [user, setUser] = useState({
    role: "driver", // Default to driver, can be changed to passenger
  });
  const [ratingResponse, setRatingResponse] = useState({});
  const [telegramId, setTelegramId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    company: false,
    model: false,
    number: false,
    color: false,
  });
  const [vehicle, setVehicle] = useState({
    company: "",
    model: "",
    number: "",
    color: "",
  });

  const getSettings = useCallback(async () => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const telegram_id = params.get("telegram_id");
    if (!telegram_id) {
      setLoading(false);
      return;
    }
    setTelegramId(telegram_id);
    try {
      const {
        settings: {
          user: { role },
          vehicle,
        },
      } = await httpService.getSettings(telegram_id);
      setUser({ role });
      if (vehicle) setVehicle(vehicle);
      setLoading(false);
    } catch (e) {
      await httpService.sendToSupport({
        message: "frontend getSettings " + e.message,
        Stack: e.stack ? e.stack : "",
      });
      // setLoading(false);
      setRatingResponse({
        status: "error",
        message: e.message ?? "Nomalum hato",
      });
    }
  }, []);

  useEffect(() => {
    tg?.ready();
    getSettings();
  }, [getSettings]);

  const handleUserChange = (event) => {
    const { name, value } = event.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };
  const handleVehicleChange = (event) => {
    const { name, value } = event.target;
    if (name === "company") {
      setVehicle((prev) => ({ ...prev, model: "" }));
    }
    setVehicle((prev) => ({ ...prev, [name]: value }));
  };
  const checkRowError = () => {
    if (
      user.role === "driver" &&
      (!vehicle.company || !vehicle.model || !vehicle.number || !vehicle.color)
    ) {
      setError({
        company: !vehicle.company,
        model: !vehicle.model,
        number: !vehicle.number,
        color: !vehicle.color,
      });
      return true;
    }
    return false;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (checkRowError()) {
      return;
    }
    setLoading(true);
    try {
      const res = await httpService.updateSettings({
        telegram_id: telegramId,
        user,
        vehicle,
      });
      tg.sendData(JSON.stringify({ action_type: "SETTINGS" }));
      if (res.ok) {
        setLoading(false);
        setRatingResponse({
          status: "success",
          message: "Izoh muvaffaqiyatli qoldirildi!",
        });
        setTimeout(() => {
          tg.close();
        }, 3000);
      }
    } catch (e) {
      await httpService.sendToSupport({
        message: "frontend updateSettings" + e.message,
        Stack: e.stack ? e.stack : "",
      });
      setLoading(false);
      setRatingResponse({
        status: "error",
        message: e.message,
      });
    }
  };
  // <div style={{ padding: "20px" }}>
  // {Object.keys(ratingResponse).length ? (
  //   <StackAlert
  //     status={ratingResponse.status}
  //     message={ratingResponse.message}
  //   />
  // ) : (
  //   <></>
  // )}
  return (
    <div style={{ padding: "20px" }}>
      {Object.keys(ratingResponse).length ? (
        <StackAlert
          status={ratingResponse.status}
          message={ratingResponse.message}
        />
      ) : (
        <></>
      )}
      {!telegramId ? (
        <>!telegramId</>
      ) : !loading ? (
        <form onSubmit={handleSubmit}>
          <div className="marginBottom10">
            <FormControl>
              <RadioGroup
                row
                aria-label="role"
                name="role"
                value={user.role}
                onChange={handleUserChange}
              >
                <FormControlLabel
                  value="driver"
                  control={<Radio />}
                  label="Haydovchi"
                />
                <FormControlLabel
                  value="passenger"
                  control={<Radio />}
                  label="Yo'lovchi"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {user.role === "driver" && (
            <div>
              <TextField
                margin="normal"
                fullWidth
                select
                label="Avto kompaniyasi *"
                error={error.company}
                helperText={"Qator to'ldirishi shart"}
                name="company"
                value={vehicle.company}
                onChange={handleVehicleChange}
              >
                {Object.keys(allVehicles).map((company) => (
                  <MenuItem value={company}>{company}</MenuItem>
                ))}
              </TextField>

              {vehicle.company && (
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Avto Modeli *"
                  error={error.model}
                  helperText={"Qator to'ldirishi shart"}
                  name="model"
                  value={vehicle.model}
                  onChange={handleVehicleChange}
                >
                  {allVehicles[vehicle.company]?.map((model) => (
                    <MenuItem value={model}>{model}</MenuItem>
                  ))}
                </TextField>
              )}
              <div>
                <Link
                  sx={{ fontSize: "14px" }}
                  href={`${process.env.REACT_APP_WEB_SUPPORT_URL}/support?telegram_id=${telegramId}`}
                  underline="none" // Removes underline if needed, can be set to 'hover' or 'always'
                >
                  Keltirilgan ro'yhatda mening avtomobilim yo'q
                </Link>
              </div>
              <TextField
                margin="normal"
                fullWidth
                label="Avto raqami *"
                error={error.number}
                helperText={"Qator to'ldirishi shart"}
                name="number"
                value={vehicle.number}
                onChange={handleVehicleChange}
              />

              <TextField
                margin="normal"
                fullWidth
                label="Avto rangi *"
                error={error.color}
                helperText={"Qator to'ldirishi shart"}
                name="color"
                value={vehicle.color}
                onChange={handleVehicleChange}
                sx={{ marginBottom: "20px" }}
              />
            </div>
          )}
          <Button
            disabled={loading}
            variant="contained"
            size="large"
            type="submit"
            color="primary"
            fullWidth
          >
            Saqlash
          </Button>
        </form>
      ) : (
        <Box
          sx={{
            display: "fixed",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Settings;
