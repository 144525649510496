import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import httpService from "../services/http.service";
const Searches = () => {
  const [expanded, setExpanded] = useState(false);
  const [rides, setRides] = useState([]);
  const [directionData, setDirectionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getRides = useCallback(async () => {
    const { data } = await httpService.getSearchesAreaLevel1(
      "passenger",
      "day"
    );
    const sortedRides = data.sort(
      (a, b) => parseFloat(b.search_count) - parseFloat(a.search_count)
    );
    setRides(sortedRides);
  }, [setRides]);
  useEffect(() => {
    getRides();
  }, [getRides]);
  const handleChangeDirectionSearches =
    (ride, index) => (event, isExpanded) => {
      setExpanded(isExpanded ? `panel${index}` : false);
      if (isExpanded) {
        getSearchesByDirections(ride);
      }
    };
  const getSearchesByDirections = async (ride) => {
    setLoading(true);
    try {
      const { rides } = await httpService.getSearchesByDirections(
        ride.from_area,
        ride.to_area,
        "passenger",
        "day"
      );
      setDirectionData(rides);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ margin: "20px" }}>
      {rides.map((ride, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChangeDirectionSearches(ride, index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6">{`${ride.from_area} ➡️⬅️ ${ride.to_area} (${ride.search_count})`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loading ? (
              <CircularProgress />
            ) : (
              <List>
                {directionData?.length &&
                  directionData.map((ride, index) => (
                    <React.Fragment key={index}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={`Jo'nash joyi: ${
                            ride.departure_place_address || ""
                          } ${ride.departure_area_level_2 || ""}`}
                          secondary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Yetib kelish joyi:{" "}
                                {ride.arrival_place_address || ""}{" "}
                                {ride.arrival_area_level_2 || ""}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      {/* {index < groupedRides[key].length - 1 && <Divider component="li" />} */}
                    </React.Fragment>
                  ))}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Searches;
