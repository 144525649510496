import React from "react";
import { Paper, Grid, Skeleton, Box } from "@mui/material";

const RideCardSkeleton = () => {
  return (
    <>
      {Array.from([1, 1, 1, 1]).map((item, index) => (
        <Paper
          key={index}
          elevation={3}
          sx={{
            backgroundColor: "var(--tg-theme-bg-color)",
            backgroundImage: "none",
            p: 2,
            margin: "10px 0 10px 0px",
            width: "calc(100% - 2px)",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm container spacing={2}>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={24} />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                display="flex"
                alignItems="center"
              >
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton variant="text" sx={{ ml: 1, flexGrow: 1 }} />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                display="flex"
                alignItems="center"
              >
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton variant="text" sx={{ ml: 1, flexGrow: 1 }} />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                display="flex"
                alignItems="center"
              >
                <Skeleton variant="circular" width={24} height={24} />
                <Box sx={{ ml: 1 }}>
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="60%" />
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                display="flex"
                alignItems="center"
              >
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton variant="text" sx={{ ml: 1, flexGrow: 1 }} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
};

export default RideCardSkeleton;
