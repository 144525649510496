export const setFilterValue = () => ({
  type: "SET_FILTER_VALUE",
});
export const setFilter = (params) => ({
  type: "SET_FILTER",
  params,
});
export const initFilter = (params) => ({
  type: "INIT_FILTER",
  params,
});
