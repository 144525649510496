const initialState = {
    isRidesOpen: false
}

const rideReducer = (state = initialState, action)=>{
    switch(action.type){
        case 'TOGGLE_RIDES_SWIPE':
            return {
                ...state,
                isRidesOpen: !state.isRidesOpen
            }
        default:
            return state;
    }
}

export default rideReducer;